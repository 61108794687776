import Header from "@/organisms/Header";
import { BlueprintSearchingProvider } from "@/utils/blueprint";
import { NotificationProvider } from "@/utils/notification";
import { UserAuthProvider } from "@/utils/userAuth";
import type { AppProps } from "next/app";
import "../styles/globals.css";
import { type AffiliateCreateRequest, create } from "@/api/affiliate";
import { get as getUserProfile } from "@/api/userProfile";
import { useShowSuccess } from "@/hooks/notification";
import { useCurrentUser, useCurrentUserState } from "@/hooks/user";
import HelpPopper from "@/molecules/HelpPopper";
import UserProfileDialog from "@/molecules/UserProfileDialog";
import HomepageSideManu from "@/organisms/HomePageSideMenu";
import { envConfig } from "@/utils/env";
import { GA_TRACKING_ID, pageview } from "@/utils/gtag";
import { rollbarConfig } from "@/utils/rollbar";
import {
	UserSideMenuDrawerProvider,
	useSetShowSideMenuDrawer,
	useShowSideMenuDrawer,
} from "@/utils/sidemenu";
import { CurrentSnapshotProvider } from "@/utils/snapshot";
import styled from "@emotion/styled";
import {
	Backdrop,
	Box,
	CircularProgress,
	Drawer,
	useMediaQuery,
} from "@mui/material";
import { ErrorBoundary, Provider } from "@rollbar/react";
import Cookies from "js-cookie";
import type { NextComponentType, NextPageContext } from "next";
import { usePathname, useSearchParams } from "next/navigation";
import { useRouter } from "next/router";
import React from "react";

declare global {
	interface Window {
		Intercom: any;
	}
}

const Wrapper = styled.div<{
	isUserPage: boolean;
	isReportConditionPage: boolean;
}>`
  // ナビバーとフッターの250pxを引いている
  /* min-height: calc(100vh - 230px); */
  min-height: 100vh;
  background-color: ${(props) => {
		if (props.isUserPage) {
			return "#f5f5f5";
		}
		if (props.isReportConditionPage) {
			return "#F7E9D7";
		}
		return "white";
	}};
`;

type Props = {
	Component: NextComponentType<NextPageContext, any, any>;
	pageProps: any;
};

const App: React.FC<AppProps> = ({ Component, pageProps }: AppProps) => {
	const router = useRouter();
	const isMobile = useMediaQuery("(max-width:600px)");
	const isUserPage =
		router.pathname === "/mypage" ||
		router.pathname === "/mypage/user_profile" ||
		router.pathname === "/mypage/payment_method" ||
		router.pathname === "/mypage/payment_statement" ||
		router.pathname === "/mypage/subscribe" ||
		router.pathname === "/notification" ||
		router.pathname === "/home";
	const isReportConditionPage =
		router.pathname === "/" ||
		router.pathname === "/blueprint/[id]" ||
		router.pathname === "/report/paste" ||
		router.pathname === "/report/import";
	const isShowHelpPopper =
		router.pathname === "/" ||
		router.pathname === "/home" ||
		router.pathname === "/blueprint/[id]" ||
		router.pathname === "/report/paste" ||
		router.pathname === "/report/import" ||
		(!isMobile && router.pathname === "/report/[blueprint_id]");

	return (
		<>
			<Header />
			<Provider config={rollbarConfig}>
				<ErrorBoundary>
					<NotificationProvider>
						<UserSideMenuDrawerProvider>
							<BlueprintSearchingProvider>
								<CurrentSnapshotProvider>
									<UserAuthProvider>
										<Wrapper
											isUserPage={isUserPage}
											isReportConditionPage={isReportConditionPage}
										>
											{isShowHelpPopper && <HelpPopper />}

											{isUserPage && !isMobile ? (
												<>
													<Box sx={{ display: "flex" }}>
														<HomepageSideManu />
														<Box sx={{ width: "100%" }}>
															<AppInit
																Component={Component}
																pageProps={pageProps}
															/>
														</Box>
													</Box>
												</>
											) : (
												<>
													<Box sx={{ display: "flex" }}>
														<Box sx={{ width: "100%" }}>
															<AppInit
																Component={Component}
																pageProps={pageProps}
															/>
														</Box>
													</Box>
												</>
											)}
										</Wrapper>
									</UserAuthProvider>
								</CurrentSnapshotProvider>
							</BlueprintSearchingProvider>
						</UserSideMenuDrawerProvider>
					</NotificationProvider>
				</ErrorBoundary>
			</Provider>
		</>
	);
};

const AppInit: React.FC<Props> = ({ Component, pageProps }) => {
	const router = useRouter();
	const { ref, signin, signup } = router.query;
	const currentUser = useCurrentUser();
	const currentUserState = useCurrentUserState();
	const showSuccess = useShowSuccess();
	const isAdminPage = router.asPath.startsWith("/_admin");
	const pathname = usePathname();
	const searchParams = useSearchParams();
	const showSideMenuDrawer = useShowSideMenuDrawer();
	const setShowSideMenuDrawer = useSetShowSideMenuDrawer();
	const isMobile = useMediaQuery("(max-width:600px)");
	const [isOpenUserProfileDialog, setIsOpenUserProfileDialog] =
		React.useState(false);
	const isUserPage =
		router.pathname === "/mypage" ||
		router.pathname === "/mypage/user_profile" ||
		router.pathname === "/mypage/payment_method" ||
		router.pathname === "/mypage/payment_statement" ||
		router.pathname === "/mypage/subscribe" ||
		router.pathname === "/notification" ||
		router.pathname === "/home";

	const affiliate = React.useCallback(async () => {
		const affiliater_identifier = Cookies.get("affiliater_identifier");
		if (!currentUser || !affiliater_identifier) return;

		try {
			const params: AffiliateCreateRequest = {
				affiliater_identifier: affiliater_identifier,
			};
			await create(params);
		} catch (err) {
			// showErrorしない
		}
	}, [currentUser]);

	const handleCloseUserProfileDialog = React.useCallback(() => {
		setIsOpenUserProfileDialog(false);
	}, []);

	const fetchUserProfile = React.useCallback(async () => {
		if (currentUser && currentUser.role !== "guest") {
			try {
				await getUserProfile();
			} catch (err) {
				setIsOpenUserProfileDialog(true);
			}
		}
	}, [currentUser]);

	React.useEffect(() => {
		affiliate();
	}, [affiliate]);

	React.useEffect(() => {
		fetchUserProfile();
	}, [fetchUserProfile]);

	React.useEffect(() => {
		if (!GA_TRACKING_ID) return;

		const url = pathname + searchParams.toString();
		pageview(url);
	}, [pathname, searchParams]);

	React.useEffect(() => {
		if (ref != null) {
			Cookies.set("affiliater_identifier", ref as string, { expires: 365 });
			affiliate();
			router.push({
				pathname: router.pathname,
				query: {},
			});
		}
	}, [ref, router, affiliate]);

	React.useEffect(() => {
		if (!GA_TRACKING_ID) return;
		if (currentUserState === "idle" || currentUser == null) {
			return;
		}

		gtag("set", "user_properties", {
			user_role: currentUser.role,
		});
	}, [currentUserState, currentUser]);

	React.useEffect(() => {
		if (currentUserState !== "idle" && currentUser && signin === "success") {
			showSuccess("ログインしました");
			router.push({
				pathname: router.pathname,
				query: ref ? { ref: ref } : {},
			});
		}
	}, [currentUserState, currentUser, signin, showSuccess, router, ref]);

	React.useEffect(() => {
		if (currentUserState !== "idle" && currentUser && signup === "success") {
			showSuccess("新規登録しました");
			router.push({
				pathname: router.pathname,
				query: ref ? { ref: ref } : {},
			});
		}
	}, [currentUserState, currentUser, signup, showSuccess, router, ref]);

	if (
		currentUserState !== "idle" &&
		currentUser &&
		currentUser.role !== "admin" &&
		isAdminPage
	) {
		router.push("/");
	}

	React.useEffect(() => {
		window.Intercom("boot", {
			api_base: "https://api-iam.intercom.io",
			app_id: envConfig.NEXT_PUBLIC_INTERCOM_APP_ID,
		});

		return () => {
			window.Intercom("shutdown");
		};
	}, []);

	return (
		<>
			{isOpenUserProfileDialog && (
				<UserProfileDialog
					isOpenUserProfileDialog={isOpenUserProfileDialog}
					onCloseDialog={handleCloseUserProfileDialog}
				/>
			)}

			{isUserPage && isMobile && (
				<Drawer
					open={showSideMenuDrawer}
					onClose={() => setShowSideMenuDrawer(false)}
				>
					<HomepageSideManu />
				</Drawer>
			)}
			{!(
				currentUserState !== "idle" &&
				currentUser &&
				currentUser.role !== "admin" &&
				isAdminPage
			) && <Component {...pageProps} />}
			{currentUserState === "idle" && (
				<Box sx={{ textAlign: "center", pt: 4 }}>
					<Backdrop
						sx={{
							zIndex: (theme) => theme.zIndex.drawer + 1,
						}}
						open
					>
						<CircularProgress color="inherit" />
					</Backdrop>
				</Box>
			)}
		</>
	);
};

export default App;
