import {
  Dialog,
  IconButton,
  Typography,
  Box,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import Link from "next/link";
import React from "react";
import GeneralButton from "./GeneralButton";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useRouter } from "next/router";

type Props = {
  showPlanDetailDialog: boolean;
  handleCloseDialog: () => void;
};

const faqList = [
  {
    question: <>プレミアムプランはいつでも解約できますか？</>,
    answer: (
      <>
        プレミアムプランはいつでも解約可能でございます。また、月額プラン、年額プランから解約される場合の利用可能日数に関しては以下の通りです。
        <br />
        ・月額プランから解約した場合：解約完了時に契約期間の残り日数分は破棄されます。
        <br />
        例: 1/1に月額プランを契約（2/1まで契約期間）→1/15に解約→
        本来、契約期間である残り日数分は利用できなくなります。
        <br />
        ・年額プランから解約した場合：解約完了しても契約期間の残り日数分はご利用いただけます。
        <br />
        例: 2024 3/1に年額プランを契約（2025
        3/1まで契約期間）→契約期間内に解約→2025
        3/1までの契約期間内は問題なくご利用いただけます。
      </>
    ),
  },
  {
    question: <>お支払い方法は何に対応していますか？</>,
    answer: (
      <>
        現時点のお支払い方法は、クレジットカード決済のみとなっております。クレジットカードで簡単にご決済頂けます。
      </>
    ),
  },
  {
    question: <>月額プランと年額プランの違いは？</>,
    answer: (
      <>
        年額プランは月額プランに比べて6ヶ月分実質無料でご利用いただけます。また契約期間の長さによって、お支払い金額が異なるだけで、ご利用いただく機能に違いはございません。
      </>
    ),
  },
];

const PlanDetailDialog: React.FC<Props> = ({
  showPlanDetailDialog,
  handleCloseDialog,
}) => {
  const router = useRouter();
  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <>
      <Dialog
        onClose={handleCloseDialog}
        open={showPlanDetailDialog}
        fullWidth
        maxWidth={"md"}
        sx={{
          "& .dialogPaper": {
            py: 2,
            borderRadius: 5,
          },
        }}
        PaperProps={{ className: "dialogPaper" }}
      >
        <IconButton
          onClick={handleCloseDialog}
          sx={{
            right: 8,
            top: 8,
            position: "absolute",
          }}
        >
          <CloseIcon />
        </IconButton>

        <Typography sx={{ textAlign: "center", fontSize: 20, fontWeight: 600 }}>
          プラン料金
        </Typography>

        <Typography sx={{ textAlign: "center", mt: 2, fontWeight: 600 }}>
          年額プランなら
          <Typography
            component="span"
            sx={{ color: "#FF0000", fontWeight: 600 }}
          >
            <Typography
              component="span"
              sx={{
                color: "#FF0000",
                fontWeight: 600,
                textDecoration: "underline",
              }}
            >
              6ヶ月分
            </Typography>
            お得
          </Typography>
        </Typography>

        <Typography
          sx={{
            textAlign: "center",
            color: "rgba(37, 37, 37, 0.70)",
            mt: 1,
            fontSize: 13,
          }}
        >
          ※
          サービスに満足いただけない等ございましたら、いつでも解約することが可能です。
        </Typography>

        <Box
          sx={
            isMobile
              ? {
                  mt: 4,
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }
              : {
                  mt: 8,
                  display: "flex",
                  width: "100%",
                }
          }
        >
          <Box
            sx={
              isMobile
                ? {
                    position: "relative",
                    border: "1px solid #D9D9D9",
                    mx: "auto",
                    px: 2,
                    pt: 3.5,
                    pb: 2,
                    width: "80%",
                    boxShadow: 2,
                    borderRadius: 1,
                  }
                : {
                    position: "relative",
                    border: "1px solid #D9D9D9",
                    mr: 1,
                    ml: 2,
                    px: 2,
                    pt: 3.5,
                    pb: 2,
                    width: "50%",
                    boxShadow: 2,
                    borderRadius: 1,
                  }
            }
          >
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: 600,
                color: "rgba(37, 37, 37, 0.70)",
                mb: 1,
              }}
            >
              プレミアム月額プラン
            </Typography>
            <Typography
              sx={{
                color: "rgba(37, 37, 37, 0.50)",
                fontSize: 14,
              }}
            >
              毎月請求
            </Typography>
            <Typography sx={{ fontSize: 30, fontWeight: 600, mt: 1 }}>
              ￥1,380/月
              <Typography
                component="span"
                sx={{ fontSize: 13, color: "rgba(37, 37, 37, 0.50)" }}
              >
                (税込)
              </Typography>
            </Typography>
            <Typography
              sx={{
                fontSize: 13,
                color: "rgba(37, 37, 37, 0.50)",
                mt: 3,
                mb: 6,
              }}
            >
              プレミアムプランは全ての機能が制限なしにご利用いただけます。
            </Typography>
            <GeneralButton
              toneType="orange"
              message="アップグレード"
              sx={{ px: 3, position: "absolute", bottom: 10 }}
              onClick={() => router.push("/plan?plan_identifier=monthly_plan")}
            />
          </Box>
          <Tooltip
            title="年間 ￥8,280円お得！"
            placement="top"
            arrow
            open={!isMobile}
            disableHoverListener
            PopperProps={{
              sx: {
                ".MuiTooltip-tooltip": {
                  background: "#E53E3E",
                  color: "white",
                  fontSize: 16,
                  borderRadius: 2,
                  py: 1,
                },
                ".MuiTooltip-arrow": {
                  "::before": {
                    background: "#E53E3E",
                  },
                },
              },
            }}
          >
            <Box
              sx={
                isMobile
                  ? {
                      position: "relative",
                      border: "3px solid #FE9719",
                      mx: "auto",
                      px: 2,
                      pt: 3.5,
                      pb: 2,
                      mt: 2,
                      width: "80%",
                      boxShadow: 2,
                      borderRadius: 1,
                    }
                  : {
                      position: "relative",
                      border: "3px solid #FE9719",
                      mr: 2,
                      ml: 1,
                      px: 2,
                      pt: 3.5,
                      pb: 2,
                      width: "50%",
                      boxShadow: 2,
                      borderRadius: 1,
                    }
              }
            >
              <Chip
                variant="filled"
                label="一番人気"
                sx={{
                  position: "absolute",
                  right: 10,
                  top: 5,
                  boxShadow: 3,
                  color: "white",
                  borderRadius: 1,
                  height: 22,
                  fontSize: 11,
                  background: "linear-gradient(to right, #ff4d00, #ebc392)",
                }}
              />
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 600,
                  color: "#FFAE64",
                  mb: 1,
                }}
              >
                プレミアム年額プラン
              </Typography>
              <Typography
                sx={{
                  color: "rgba(37, 37, 37, 0.50)",
                  fontSize: 14,
                }}
              >
                ￥8,280円 毎年請求
              </Typography>
              <Typography sx={{ fontSize: 30, fontWeight: 600, mt: 1 }}>
                ￥690/月
                <Typography
                  component="span"
                  sx={{ fontSize: 13, color: "rgba(37, 37, 37, 0.50)" }}
                >
                  (税込)
                </Typography>
              </Typography>
              <Typography
                sx={{
                  fontSize: 13,
                  color: "rgba(37, 37, 37, 0.50)",
                  mt: 3,
                  mb: 6,
                }}
              >
                月額プランに比べて、6ヶ月分お得なため一番人気のプランになっております。
              </Typography>
              <GeneralButton
                toneType="orange"
                message="アップグレード"
                sx={{ px: 3, position: "absolute", bottom: 10 }}
                onClick={() => router.push("/plan?plan_identifier=annual_plan")}
              />
            </Box>
          </Tooltip>
          <Box
            sx={
              isMobile
                ? {
                    position: "relative",
                    border: "1px solid #D9D9D9",
                    mx: "auto",
                    px: 2,
                    pt: 3.5,
                    pb: 2,
                    width: "80%",
                    boxShadow: 2,
                    borderRadius: 1,
                  }
                : {
                    position: "relative",
                    border: "1px solid #D9D9D9",
                    mr: 1,
                    ml: 2,
                    px: 2,
                    pt: 3.5,
                    pb: 2,
                    width: "50%",
                    boxShadow: 2,
                    borderRadius: 1,
                  }
            }
          >
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: 600,
                color: "rgba(37, 37, 37, 0.70)",
                mb: 1,
              }}
            >
              プレミアム永年プラン
            </Typography>
            <Typography
              sx={{
                color: "rgba(37, 37, 37, 0.50)",
                fontSize: 14,
              }}
            >
              一回買い切り
            </Typography>
            <Typography sx={{ fontSize: 30, fontWeight: 600, mt: 1 }}>
              16,560/永年
              <Typography
                component="span"
                sx={{ fontSize: 13, color: "rgba(37, 37, 37, 0.50)" }}
              >
                (税込)
              </Typography>
            </Typography>
            <Typography
              sx={{
                fontSize: 13,
                color: "rgba(37, 37, 37, 0.50)",
                mt: 3,
                mb: 6,
              }}
            >
              月額プラン12ヶ月分の料金で、すべての機能を永年ご利用いただけます。
            </Typography>
            <GeneralButton
              toneType="orange"
              message="アップグレード"
              sx={{ px: 3, position: "absolute", bottom: 10 }}
              onClick={() => router.push("/plan?plan_identifier=eternal_plan")}
            />
          </Box>
        </Box>

        <Box sx={{ mx: 1, mt: 4, mb: 4 }}>
          {faqList.map((faq, index) => (
            <Accordion
              key={index}
              elevation={0}
              disableGutters
              sx={{
                borderTop: "1px solid rgba(0, 0, 0, .125)",
                fontSize: 13,
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {faq.question}
              </AccordionSummary>
              <AccordionDetails>{faq.answer}</AccordionDetails>
            </Accordion>
          ))}
        </Box>

        <Typography sx={{ fontSize: 13, textAlign: "center" }}>
          他の決済やプランについてのご不明点などは、こちらの
          <Link
            href="https://help.report-supporter.com/ja/collections/8953825-%E3%82%88%E3%81%8F%E3%81%82%E3%82%8B%E8%B3%AA%E5%95%8F"
            target="_blank"
          >
            よくある質問
          </Link>
          でご覧ください。
        </Typography>
      </Dialog>
    </>
  );
};

export default PlanDetailDialog;
