import Head from "next/head";
import React from "react";

const Header: React.FC = () => {
  return (
    <>
      <Head>
        <title>レポートお助けくん</title>
        <meta
          name="description"
          content="当webサービスは、大学生のレポート作成を支援するために設計されました。トピックや参考文献などの情報を入力するだけで、精度の高いレポートを作成することができます。我々のプロのライターは、あなたの要件に合わせてレポートをカスタマイズします。レポートのトピックや文字数に関係なく、我々はあなたの要望に応じて最高の品質を提供します。"
        />
        <meta
          name="google-site-verification"
          content="2lOtk9BUcMzAGOMkjnCOG_-FnbERLn6OrGeGHFrKnAU"
        />
        <meta
          name="google-site-verification"
          content="2cdSem4aeMEXnuTQ4FseRwrtBYEZTgB2dblLJYXM-dE"
        />
        <meta
          name="google-site-verification"
          content="44hrlcrlMEqPwFxLhg-siqx4bEdXN4Ys0Xt4jqXVgHw"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
    </>
  );
};

export default Header;
