import React from "react";
import { Snapshot } from "./interfaces";

export const SupportingBlockValues = [
  "paragraph",
  "h1",
  "h2",
  "h3",
  "ul",
  "ol",
] as const;

export type SupportingBlockType = (typeof SupportingBlockValues)[number];

export const BlockTypeToBlockNameValues: {
  [key in SupportingBlockType]: string;
} = {
  paragraph: "テキスト",
  h1: "見出し１",
  h2: "見出し２",
  h3: "見出し３",
  ul: "箇条書きリスト",
  ol: "番号付きリスト",
} as const;

export const SupportingEditorFormatValues = [
  "bold",
  "italic",
  "underline",
] as const;

export type SupportingEditorFormatType =
  (typeof SupportingEditorFormatValues)[number];

export const UnClosablePopper = [
  "ai_assistant",
  "comment",
  "bad_feedback",
  "good_feedback",
  "revising_ai_action",
  "revising_option",
] as const;

export type UnClosablePopperType = (typeof UnClosablePopper)[number];

//レポート作成中に使うステータス
export const CurrentSnapshotStatusValues = [
  "not_started",
  "creating",
  "finished",
] as const;

export type SnapshotType = (typeof CurrentSnapshotStatusValues)[number];

type LexicalEditorSelectionAnchorContextType = {
  startSelectionAnchorElement: Range | null;
  setStartSelectionAnchorElement: React.Dispatch<
    React.SetStateAction<Range | null>
  >;
  endSelectionAnchorElement: Range | null;
  setEndSelectionAnchorElement: React.Dispatch<
    React.SetStateAction<Range | null>
  >;
  unClosablePoppers: UnClosablePopperType[];
  setUnClosablePoppers: React.Dispatch<
    React.SetStateAction<UnClosablePopperType[]>
  >;
};

type CurrentSnapshotContextType = {
  currentSnapshot: Snapshot | null;
  setCurrentSnapshot: React.Dispatch<React.SetStateAction<Snapshot | null>>;
  currentSnapshotStatus: SnapshotType | null;
  setCurrentSnapshotStatus: React.Dispatch<React.SetStateAction<SnapshotType | null>>;
};

export const LexicalEditorSelectionAnchorContext =
  React.createContext<LexicalEditorSelectionAnchorContextType | null>(null);

export const CurrentSnapshotContext =
  React.createContext<CurrentSnapshotContextType | null>(null);

export const LexicalEditorProvider: React.FC<{ children: React.ReactNode }> = (
  props
) => {
  const [startSelectionAnchorElement, setStartSelectionAnchorElement] =
    React.useState<Range | null>(null);
  const [endSelectionAnchorElement, setEndSelectionAnchorElement] =
    React.useState<Range | null>(null);
  const [unClosablePoppers, setUnClosablePoppers] = React.useState<
    UnClosablePopperType[]
  >([]);

  return (
    <LexicalEditorSelectionAnchorContext.Provider
      value={{
        startSelectionAnchorElement,
        setStartSelectionAnchorElement,
        endSelectionAnchorElement,
        setEndSelectionAnchorElement,
        unClosablePoppers,
        setUnClosablePoppers,
      }}
    >
      {props.children}
    </LexicalEditorSelectionAnchorContext.Provider>
  );
};

export const CurrentSnapshotProvider: React.FC<{
  children: React.ReactNode;
}> = (props) => {
  const [currentSnapshot, setCurrentSnapshot] = React.useState<Snapshot | null>(
    null
  );
  const [currentSnapshotStatus, setCurrentSnapshotStatus] = React.useState<SnapshotType | null>(
      null
  );

  return (
    <CurrentSnapshotContext.Provider
      value={{ currentSnapshot, setCurrentSnapshot ,currentSnapshotStatus,setCurrentSnapshotStatus}}
    >
      {props.children}
    </CurrentSnapshotContext.Provider>
  );
};
