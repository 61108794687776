import { CurrentAvailabilityInformation, User } from "@/utils/interfaces";
import {
  Dialog,
  Typography,
  Box,
  Divider,
  Link,
  Chip,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import GoogleAuthButton from "./GoogleAuthBotton";
import GeneralButton from "./GeneralButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import { useShowError } from "@/hooks/notification";
import { isWebView } from "@/utils/util";
import Image from "next/image";
import { DistributionCreditValue } from "@/utils/constrants";

const faqList = {
  guest: [
    {
      question: <>各機能ごとにそれぞれ何クレジット消費されますか？</>,
      answer: (
        <>
          レポートを作成: レポートを作成ごとに30クレジット <br />
          選択範囲から編集または生成する: 1つのアクションごとに5クレジット
        </>
      ),
    },
    {
      question: <>さらにクレジットを増やすにはどうすればいいですか？</>,
      answer: (
        <>
          プレミアムプランに登録すると、クレジットは無制限でご利用いただけます。
        </>
      ),
    },
    {
      question: <>課金しなくてもクレジットは無料で配布されますか？</>,
      answer: (
        <>
          ログインすると、以降毎月{DistributionCreditValue}
          クレジットが無料で配布されます。
          <br />
          ※配布されたクレジットのうちの使用していないものは翌月繰り越されず、毎月1日に
          {DistributionCreditValue}クレジットにリセットされます。
        </>
      ),
    },
  ],
  free: [
    {
      question: <>各機能ごとにそれぞれ何クレジット消費されますか？</>,
      answer: (
        <>
          レポートを作成: レポートを作成ごとに30クレジット <br />
          選択範囲から編集または生成する: 1つのアクションごとに5クレジット
        </>
      ),
    },
    {
      question: <>さらにクレジットを増やすにはどうすればいいですか？</>,
      answer: (
        <>
          プレミアムプランに登録すると、クレジットは無制限でご利用いただけます。
        </>
      ),
    },
    {
      question: <>課金しなくてもクレジットは無料で配布されますか？</>,
      answer: (
        <>
          ログインすると、以降毎月{DistributionCreditValue}
          クレジットが無料で配布されます。
          <br />
          ※配布されたクレジットのうちの使用していないものは翌月繰り越されず、毎月1日に
          {DistributionCreditValue}クレジットにリセットされます。
        </>
      ),
    },
  ],
  pro: [
    {
      question: (
        <>年額プランを途中で解約したら残りの日数分はそのまま利用できますか？</>
      ),
      answer: (
        <>
          解約しても契約期間内は通常通りご利用いただけます。
          <br />
          例: 2024 3/1に年額プランを契約（2025
          3/1まで契約期間）→契約期間内に解約→2025
          3/1までの契約期間内は問題なくご利用いただけます。
        </>
      ),
    },
    {
      question: (
        <>年額プランから月額プランに変更したときの契約期間はどうなるの？</>
      ),
      answer: (
        <>
          年額プランの次回請求日に月額プランの料金が自動で決済され、その翌日から月額プランの契約が開始されます。またその際、年額プランから月額プランに変更が完了した時から、次回請求日は年額プランの料金ではなく、月額プランの料金のみ決済されますので、ご安心ください。
        </>
      ),
    },
    {
      question: <>月額プランと年額プランの違いは？</>,
      answer: (
        <>
          年額プランは月額プランに比べて6ヶ月分実質無料でご利用いただけます。また契約期間の長さによって、お支払い金額が異なるだけで、ご利用いただく機能に違いはございません。
        </>
      ),
    },
  ],
  admin: [
    {
      question: (
        <>年額プランを途中で解約したら残りの日数分はそのまま利用できますか？</>
      ),
      answer: (
        <>
          解約しても契約期間内は通常通りご利用いただけます。
          <br />
          例: 2024 3/1に年額プランを契約（2025
          3/1まで契約期間）→契約期間内に解約→2025
          3/1までの契約期間内は問題なくご利用いただけます。
        </>
      ),
    },
    {
      question: (
        <>年額プランから月額プランに変更したときの契約期間はどうなるの？</>
      ),
      answer: (
        <>
          年額プランの次回請求日に月額プランの料金が自動で決済され、その翌日から月額プランの契約が開始されます。またその際、年額プランから月額プランに変更が完了した時から、次回請求日は年額プランの料金ではなく、月額プランの料金のみ決済されますので、ご安心ください。
        </>
      ),
    },
    {
      question: <>月額プランと年額プランの違いは？</>,
      answer: (
        <>
          年額プランは月額プランに比べて6ヶ月分実質無料でご利用いただけます。また契約期間の長さによって、お支払い金額が異なるだけで、ご利用いただく機能に違いはございません。
        </>
      ),
    },
  ],
};

type Props = {
  currentUser: User;
  showAvailableCreditDialog: boolean;
  currentAvailabilityInformation: CurrentAvailabilityInformation | null;
  handleCloseDialog: () => void;
  handleOpenPlanDetailDialog: () => void;
};

const AvailableCreditDialog: React.FC<Props> = ({
  currentUser,
  showAvailableCreditDialog,
  currentAvailabilityInformation,
  handleCloseDialog,
  handleOpenPlanDetailDialog,
}) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const showError = useShowError();

  const preventIfWebView = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (isWebView(navigator.userAgent)) {
      event.preventDefault();
      showError("メールアドレスまたは外部ブラウザでGoogle認証を行ってください");
    }
  };

  return (
    <>
      <Dialog
        onClose={handleCloseDialog}
        open={showAvailableCreditDialog}
        fullWidth
        maxWidth={"sm"}
        sx={{
          "& .dialogPaper": {
            py: 2,
            borderRadius: 5,
          },
        }}
        PaperProps={{ className: "dialogPaper" }}
      >
        <IconButton
          onClick={handleCloseDialog}
          sx={{
            right: 8,
            top: 8,
            position: "absolute",
          }}
        >
          <CloseIcon />
        </IconButton>

        {currentUser.available_credit == 0 &&
          (currentUser.role == "guest" || currentUser.role == "free") && (
            <Alert
              severity="error"
              sx={{
                width: "60%",
                mx: "auto",
                mb: 2,
                py: 0.5,
                fontSize: 12,
                color: "#A55858",
                fontWeight: 600,
              }}
            >
              {currentUser.role == "guest" &&
                "クレジットが不足しています。ログインしてください。"}
              {currentUser.role == "free" &&
                "クレジットが不足しています。アップグレードしてください。"}
            </Alert>
          )}
        <Typography
          sx={{ textAlign: "center", color: "rgba(37, 37, 37, 0.70)" }}
        >
          クレジット残高
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: 1,
          }}
        >
          <Image
            src={"/images/coins.svg"}
            alt={"coins"}
            width={isMobile ? 30 : 40}
            height={isMobile ? 30 : 40}
          />
          {currentUser.role == "pro" || currentUser.role == "admin" ? (
            <Typography sx={{ fontSize: isMobile ? 28 : 38, ml: 1 }}>
              クレジット無制限
            </Typography>
          ) : (
            <Typography
              sx={
                currentUser.available_credit == 0
                  ? { fontSize: isMobile ? 28 : 38, ml: 1, color: "#FF0000" }
                  : { fontSize: isMobile ? 28 : 38, ml: 1 }
              }
            >
              {currentUser.available_credit}クレジット
            </Typography>
          )}
        </Box>

        <Divider sx={{ width: isMobile ? "90%" : "60%", mx: "auto" }} />

        <Typography
          sx={{
            textAlign: "center",
            color: "rgba(37, 37, 37, 0.70)",
            mt: 1,
            mx: 1,
            fontSize: isMobile ? 14 : 16,
          }}
        >
          {currentUser.role == "guest" && (
            <>
              ログインすると毎月
              <Typography
                sx={{ color: "#20BF7A", fontSize: isMobile ? 14 : 16 }}
                component="span"
              >
                最大{DistributionCreditValue}クレジット
              </Typography>
              が無料で自動配布されます。
            </>
          )}
          {currentUser.role == "free" && (
            <>毎月初日に100クレジットにリセットされます。</>
          )}
          {currentUser.role == "pro" && (
            <>プレミアム会員の方はクレジットを無制限に使うことができます。</>
          )}
        </Typography>

        <Box sx={{ mt: 3 }}>
          {currentUser.role == "guest" && (
            <>
              <Typography
                sx={{
                  fontSize: isMobile ? 10 : 12,
                  color: "rgba(37, 37, 37, 0.70)",
                  textAlign: "center",
                }}
              >
                ※Googleアカウントで簡単にログインできます。
              </Typography>
              <Box sx={{ mx: "auto", textAlign: "center", my: 1 }}>
                <GoogleAuthButton
                  onClick={preventIfWebView}
                  message="Googleでログイン"
                  toneType={"white"}
                  sx={{ width: 250 }}
                  authType="signin"
                />
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                <Typography
                  sx={{ color: "rgba(37, 37, 37, 0.70)", fontSize: 12 }}
                >
                  ※ログインすることで、{" "}
                  <Link href="/tos" target="_blank">
                    利用規約
                  </Link>
                  および
                  <Link href="/privacy" target="_blank">
                    プライバシーポリシー
                  </Link>
                  に同意したものとみなします。
                </Typography>
              </Box>
            </>
          )}

          {(currentUser.role == "free" ||
            currentUser.role == "pro" ||
            currentUser.role == "admin") && (
            <>
              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: 600,
                  fontSize: isMobile ? 14 : 16,
                }}
              >
                {currentUser.role == "free" && <>クレジットを無制限にする</>}
                {currentUser.role == "pro" &&
                  currentAvailabilityInformation?.billing_plan_identifier ==
                    "monthly_plan" && <>プレミアム年額プランに変更する</>}
                {currentUser.role == "pro" &&
                  currentAvailabilityInformation?.billing_plan_identifier ==
                    "annual_plan" && <>プレミアム永年プランに変更する</>}
              </Typography>
              {currentAvailabilityInformation?.billing_plan_identifier !=
                "eternal_plan" && (
                <Box sx={{ border: "1px solid #D9D9D9", mx: 2, p: 1, mb: 3 }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mb: 1,
                    }}
                  >
                    <Chip
                      variant="filled"
                      label="プレミアム"
                      sx={{
                        boxShadow: 3,
                        color: "white",
                        borderRadius: 2,
                        background:
                          "linear-gradient(to right, #ff4d00, #ebc392)",
                      }}
                    />
                    <Typography
                      sx={{
                        fontWeight: 600,
                        ml: 1,
                        fontSize: isMobile ? 12 : 14,
                      }}
                    >
                      {currentUser.role == "free" && (
                        <>プレミアムプランにアップグレード</>
                      )}
                      {currentUser.role == "pro" &&
                        currentAvailabilityInformation?.billing_plan_identifier ==
                          "monthly_plan" && (
                          <>プレミアム年額プランにアップグレード</>
                        )}
                      {currentUser.role == "pro" &&
                        currentAvailabilityInformation?.billing_plan_identifier ==
                          "annual_plan" && (
                          <>プレミアム永年プランにアップグレード</>
                        )}
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      color: "rgba(37, 37, 37, 0.70)",
                      fontSize: isMobile ? 12 : 14,
                      textAlign: "center",
                    }}
                  >
                    {currentUser.role == "free" && (
                      <>月690円〜、クレジット無制限でご利用いただけます。</>
                    )}
                    {currentUser.role == "pro" &&
                      currentAvailabilityInformation?.billing_plan_identifier ==
                        "monthly_plan" && (
                        <>
                          プレミアム年額プランに変更すると、実質6ヶ月分が無料でご利用いただけます。
                        </>
                      )}
                    {currentUser.role == "pro" &&
                      currentAvailabilityInformation?.billing_plan_identifier ==
                        "annual_plan" && (
                        <>
                          プレミアム永年プランに変更すると、12ヶ月分の料金で無制限にご利用いただけます。
                        </>
                      )}
                  </Typography>
                </Box>
              )}

              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <GeneralButton
                  toneType="orange"
                  message="プランの詳細"
                  onClick={() => {
                    handleOpenPlanDetailDialog();
                    handleCloseDialog();
                  }}
                />
              </Box>
            </>
          )}
        </Box>

        <Box sx={{ mx: 1, mt: 4, mb: 4 }}>
          {faqList[currentUser.role] &&
            faqList[currentUser.role].map((faq, index) => (
              <Accordion
                key={index}
                elevation={0}
                disableGutters
                sx={{
                  borderTop: "1px solid rgba(0, 0, 0, .125)",
                  fontSize: 13,
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  {faq.question}
                </AccordionSummary>
                <AccordionDetails>{faq.answer}</AccordionDetails>
              </Accordion>
            ))}
        </Box>

        <Typography sx={{ fontSize: 13, textAlign: "center", mx: 1 }}>
          他の決済やプランについてのご不明点などは、こちらの
          <Link
            href="https://help.report-supporter.com/ja/collections/8953825-%E3%82%88%E3%81%8F%E3%81%82%E3%82%8B%E8%B3%AA%E5%95%8F"
            target="_blank"
          >
            よくある質問
          </Link>
          でご覧ください。
        </Typography>
      </Dialog>
    </>
  );
};

export default AvailableCreditDialog;
