import { getCurrentAvailabilityInformation } from "@/api/enrollmentRecord";
import { useCurrentUser } from "@/hooks/user";
import AvailableCreditDialog from "@/molecules/AvailableCreditDialog";
import GeneralButton from "@/molecules/GeneralButton";
import PlanDetailDialog from "@/molecules/PlanDetailDialog";
import UserProfilePopup from "@/molecules/UserProfilePopup";
import { useSetCurrentKeyword } from "@/utils/blueprint";
import { DistributionCreditValue } from "@/utils/constrants";
import type { CurrentAvailabilityInformation } from "@/utils/interfaces";
import {
	AssignmentOutlined as AssignmentOutlinedIcon,
	CreditCard as CreditCardIcon,
	HistoryOutlined as HistoryOutlinedIcon,
	InventoryOutlined as InventoryOutlinedIcon,
	MailOutline as MailOutlineIcon,
	PersonOutlineOutlined as PersonOutlineOutlinedIcon,
} from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import SearchIcon from "@mui/icons-material/Search";
import {
	Avatar,
	Box,
	Divider,
	IconButton,
	InputAdornment,
	Link,
	ListItemIcon,
	MenuItem,
	MenuList,
	TextField,
	Typography,
} from "@mui/material";
import Image from "next/image";
import { useRouter } from "next/router";
import React from "react";
import BlueprintSearchDialog from "./BlueprintSearchDialog";

const HomePageSideMenu: React.FC = () => {
	const currentUser = useCurrentUser();
	const router = useRouter();
	const setCurrentKeyword = useSetCurrentKeyword();
	const [openSearchingDialog, setOpenSearchingDialog] = React.useState(false);
	const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
	const [showAvailableCreditDialog, setShowAvailableCreditDialog] =
		React.useState(false);
	const [showPlanDetailDialog, setShowPlanDetailDialog] = React.useState(false);
	const [currentAvailabilityInformation, setCurrentAvailabilityInformation] =
		React.useState<CurrentAvailabilityInformation | null>(null);

	const handleOpenPlanDetailDialog = React.useCallback(() => {
		setShowPlanDetailDialog(true);
	}, []);

	const handleClosePlanDetailDialog = React.useCallback(() => {
		setShowPlanDetailDialog(false);
	}, []);

	const handleClickAvailableCredit = React.useCallback(() => {
		setShowAvailableCreditDialog(true);
	}, []);

	const handleCloseAvailableCreditDialog = React.useCallback(() => {
		setShowAvailableCreditDialog(false);
	}, []);

	const fetchCurrentAvailabilityInformation = React.useCallback(async () => {
		try {
			const response = await getCurrentAvailabilityInformation();
			setCurrentAvailabilityInformation(response);
		} catch (err) {
			// showErrorしない
		}
	}, []);

	const handleOpenUserProfile = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleCloseUserProfile = () => {
		setAnchorEl(null);
	};

	const handleOpenSearchingDialog = React.useCallback(() => {
		setOpenSearchingDialog(true);
	}, []);

	const handleCloseSearchingDialog = React.useCallback(() => {
		setOpenSearchingDialog(false);
		setCurrentKeyword(null);
	}, [setCurrentKeyword]);

	React.useEffect(() => {
		fetchCurrentAvailabilityInformation();
	}, [fetchCurrentAvailabilityInformation]);

	if (currentUser == null) return <></>;

	return (
		<Box
			sx={{
				bgcolor: "#ffffff",
				minHeight: "calc(100vh - 24px)", // pt: 3の分を引く
				width: 300,
				pt: 3,
				display: "flex",
				justifyContent: "space-between",
				flexDirection: "column",
			}}
		>
			<PlanDetailDialog
				showPlanDetailDialog={showPlanDetailDialog}
				handleCloseDialog={handleClosePlanDetailDialog}
			/>
			{currentUser && (
				<AvailableCreditDialog
					currentUser={currentUser}
					showAvailableCreditDialog={showAvailableCreditDialog}
					currentAvailabilityInformation={currentAvailabilityInformation}
					handleCloseDialog={handleCloseAvailableCreditDialog}
					handleOpenPlanDetailDialog={handleOpenPlanDetailDialog}
				/>
			)}
			{openSearchingDialog && (
				<BlueprintSearchDialog
					openSearchingDialog={openSearchingDialog}
					handleCloseDialog={handleCloseSearchingDialog}
				/>
			)}

			<Box>
				<Box
					sx={{
						mb: 2,
						textAlign: "center",
					}}
				>
					<Typography sx={{ fontSize: 25, fontWeight: 900, width: "100%" }}>
						レポートお助けくん
					</Typography>
				</Box>
				<Box
					sx={
						currentUser.role == "guest"
							? {
									display: "flex",
									justifyContent: "space-between",
									mx: 2,
									alignItems: "center",
								}
							: {
									display: "flex",
									justifyContent: "space-between",
									mx: 2,
									alignItems: "center",
									cursor: "pointer",
								}
					}
					onClick={handleOpenUserProfile}
				>
					<Box sx={{ display: "flex", alignItems: "center" }}>
						<Avatar
							alt={currentUser.name}
							src={currentUser.icon}
							sx={{ mr: 1 }}
						/>
						<Box>
							<Typography sx={{ fontSize: 20 }}>
								{currentUser.role == "guest"
									? "ゲストユーザー"
									: currentUser.name}
							</Typography>
							<Typography
								sx={{ fontSize: 15, color: "rgba(37, 37, 37, 0.70)" }}
							>
								{currentUser.role == "guest" ? "" : currentUser.email}
							</Typography>
						</Box>
					</Box>
					{currentUser.role != "guest" && (
						<ExpandMoreIcon
							sx={{ fontSize: 40, color: "rgba(37, 37, 37, 0.70)" }}
						/>
					)}
				</Box>
				{currentUser.role != "guest" && (
					<UserProfilePopup
						user={currentUser}
						anchorEl={anchorEl}
						handleClose={handleCloseUserProfile}
					/>
				)}
				<Box
					sx={{ mt: 3, textAlign: "center" }}
					onClick={handleOpenSearchingDialog}
				>
					<TextField
						placeholder="レポートを検索する"
						variant="outlined"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							),
						}}
						sx={{
							mx: "auto",
							"& .MuiOutlinedInput-input": {
								p: 1.5,
							},
						}}
					/>
				</Box>
				{currentUser.role != "guest" && (
					<MenuList
						sx={{
							display: "flex",
							flexDirection: "column",
							mx: 2,
							borderRadius: 2,
						}}
					>
						<MenuItem
							onClick={() => router.push("/home")}
							sx={{
								display: "flex",
								justifyContent: "space-between",
								py: 2,
								borderRadius: 2,
							}}
						>
							<Box sx={{ display: "flex", justifyContent: "flex-start" }}>
								<ListItemIcon>
									<AssignmentOutlinedIcon
										sx={{ color: "rgba(37, 37, 37, 0.70)" }}
									/>
								</ListItemIcon>
								<Typography
									sx={
										router.pathname == "/home"
											? {
													position: "relative",
													"&::after": {
														position: "absolute",
														width: "50%",
														mx: "auto",
														height: 4,
														background: "#404147",
														left: 0,
														right: 0,
														bottom: -3,
														content: '""',
													},
												}
											: {}
									}
								>
									全てのレポート
								</Typography>
							</Box>
						</MenuItem>
						<MenuItem
							onClick={() => router.push("/mypage")}
							sx={{
								display: "flex",
								justifyContent: "space-between",
								py: 2,
								borderRadius: 2,
							}}
						>
							<Box sx={{ display: "flex", justifyContent: "flex-start" }}>
								<ListItemIcon>
									<PersonOutlineOutlinedIcon
										sx={{ color: "rgba(37, 37, 37, 0.70)" }}
									/>
								</ListItemIcon>
								<Typography
									sx={
										router.pathname == "/mypage"
											? {
													position: "relative",
													"&::after": {
														position: "absolute",
														width: "50%",
														mx: "auto",
														height: 4,
														background: "#404147",
														left: 0,
														right: 0,
														bottom: -3,
														content: '""',
													},
												}
											: {}
									}
								>
									ユーザー情報
								</Typography>
							</Box>
						</MenuItem>
						<MenuItem
							onClick={() => router.push("/notification")}
							sx={{
								display: "flex",
								justifyContent: "space-between",
								py: 2,
								borderRadius: 2,
							}}
						>
							<Box sx={{ display: "flex", justifyContent: "flex-start" }}>
								<ListItemIcon>
									<MailOutlineIcon sx={{ color: "rgba(37, 37, 37, 0.70)" }} />
								</ListItemIcon>
								<Typography
									sx={
										router.pathname == "/notification"
											? {
													position: "relative",
													"&::after": {
														position: "absolute",
														width: "50%",
														mx: "auto",
														height: 4,
														background: "#404147",
														left: 0,
														right: 0,
														bottom: -3,
														content: '""',
													},
												}
											: {}
									}
								>
									メール通知設定
								</Typography>
							</Box>
						</MenuItem>
						<MenuItem
							onClick={() => router.push("/mypage/subscribe")}
							sx={{
								display: "flex",
								justifyContent: "space-between",
								py: 2,
								borderRadius: 2,
							}}
						>
							<Box sx={{ display: "flex", justifyContent: "flex-start" }}>
								<ListItemIcon>
									<InventoryOutlinedIcon
										sx={{ color: "rgba(37, 37, 37, 0.70)" }}
									/>
								</ListItemIcon>
								<Typography
									sx={
										router.pathname == "/mypage/subscribe"
											? {
													position: "relative",
													"&::after": {
														position: "absolute",
														width: "50%",
														mx: "auto",
														height: 4,
														background: "#404147",
														left: 0,
														right: 0,
														bottom: -3,
														content: '""',
													},
												}
											: {}
									}
								>
									プランの確認・変更
								</Typography>
							</Box>
						</MenuItem>
						<MenuItem
							onClick={() => router.push("/mypage/payment_method")}
							sx={{
								display: "flex",
								justifyContent: "space-between",
								py: 2,
								borderRadius: 2,
							}}
						>
							<Box sx={{ display: "flex", justifyContent: "flex-start" }}>
								<ListItemIcon>
									<CreditCardIcon sx={{ color: "rgba(37, 37, 37, 0.70)" }} />
								</ListItemIcon>
								<Typography
									sx={
										router.pathname == "/mypage/payment_method"
											? {
													position: "relative",
													"&::after": {
														position: "absolute",
														width: "50%",
														mx: "auto",
														height: 4,
														background: "#404147",
														left: 0,
														right: 0,
														bottom: -3,
														content: '""',
													},
												}
											: {}
									}
								>
									お支払い方法
								</Typography>
							</Box>
						</MenuItem>
						<MenuItem
							onClick={() => router.push("/mypage/payment_statement")}
							sx={{
								display: "flex",
								justifyContent: "space-between",
								py: 2,
								borderRadius: 2,
							}}
						>
							<Box sx={{ display: "flex", justifyContent: "flex-start" }}>
								<ListItemIcon>
									<HistoryOutlinedIcon
										sx={{ color: "rgba(37, 37, 37, 0.70)" }}
									/>
								</ListItemIcon>
								<Typography
									sx={
										router.pathname == "/mypage/payment_statement"
											? {
													position: "relative",
													"&::after": {
														position: "absolute",
														width: "50%",
														mx: "auto",
														height: 4,
														background: "#404147",
														left: 0,
														right: 0,
														bottom: -3,
														content: '""',
													},
												}
											: {}
									}
								>
									決済履歴
								</Typography>
							</Box>
						</MenuItem>
					</MenuList>
				)}
				<Box sx={{ mx: 2, my: 1 }}>
					<Divider sx={{ borderWidth: 1, mb: 0.5 }} />
					<IconButton
						onClick={handleClickAvailableCredit}
						sx={{
							"&.MuiButtonBase-root": {
								borderRadius: 0,
							},
							width: "100%",
							py: 1.0,
						}}
					>
						<Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
							<Image
								src={"/images/coins.svg"}
								alt={"coins"}
								width={20}
								height={20}
							/>
							<Typography
								sx={
									currentUser.available_credit == 0 &&
									(currentUser.role == "guest" || currentUser.role == "free")
										? { fontSize: 15, color: "#FF0000", ml: 1 }
										: { fontSize: 15, ml: 1 }
								}
							>
								{currentUser.role == "guest" || currentUser.role == "free" ? (
									<>{currentUser.available_credit}クレジット</>
								) : (
									<>クレジット無制限</>
								)}
							</Typography>
						</Box>
					</IconButton>
					<Divider sx={{ borderWidth: 1, mt: 0.5 }} />

					<IconButton
						component="a"
						href="https://help.report-supporter.com"
						target="_blank"
						rel="noopener noreferrer"
						sx={{
							"&.MuiButtonBase-root": {
								borderRadius: 0,
							},
							width: "100%",
							mt: 1,
							py: 1.0,
						}}
					>
						<Box sx={{ width: "100%", display: "flex" }}>
							<HelpOutlineIcon />
							<Typography sx={{ fontSize: 15, ml: 1 }}>ヘルプ</Typography>
						</Box>
					</IconButton>
				</Box>
				{currentUser.role == "guest" && (
					<>
						<Box sx={{ mx: 2, my: 1 }}>
							<Typography
								sx={{ color: "rgba(37, 37, 37, 0.70)", fontSize: 14 }}
							>
								ログインで毎月{DistributionCreditValue}
								クレジットが無料で自動付与されます。
							</Typography>
						</Box>
					</>
				)}

				{currentUser.role == "free" && (
					<Box sx={{ mx: 2, mt: 2 }}>
						<Typography sx={{ color: "rgba(37, 37, 37, 0.70)" }}>
							月690円〜で、クレジット無制限でご利用できます。
						</Typography>
					</Box>
				)}

				<Box sx={{ mx: 2, mt: 2 }}>
					{currentUser.role == "guest" && (
						<>
							<GeneralButton
								onClick={() => router.push("/signup")}
								message={"新規登録"}
								toneType="light-orange"
								sx={{ px: 3 }}
							/>
						</>
					)}

					{currentUser.role == "free" && (
						<GeneralButton
							onClick={handleOpenPlanDetailDialog}
							message={"アップグレード"}
							toneType="light-orange"
							sx={{ px: 3 }}
						/>
					)}

					{(currentUser.role == "pro" || currentUser.role == "admin") && (
						<GeneralButton
							onClick={handleOpenPlanDetailDialog}
							message={"プランを表示"}
							toneType="light-orange"
							sx={{ px: 3 }}
						/>
					)}
				</Box>
			</Box>

			<Box
				sx={{
					mx: 2,
					mt: 5,
					mb: 2,
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Link
					target="_blank"
					href="/tos"
					underline="hover"
					sx={{ color: "rgba(37, 37, 37, 0.70)", fontSize: 15 }}
				>
					利用規約
				</Link>
				<Link
					target="_blank"
					href="/privacy"
					underline="hover"
					sx={{ color: "rgba(37, 37, 37, 0.70)", fontSize: 15 }}
				>
					プライバシーポリシー
				</Link>
				<Link
					target="_blank"
					href="/company"
					underline="hover"
					sx={{ color: "rgba(37, 37, 37, 0.70)", fontSize: 15 }}
				>
					会社情報
				</Link>
				<Link
					target="_blank"
					href="/specified"
					underline="hover"
					sx={{ color: "rgba(37, 37, 37, 0.70)", fontSize: 15 }}
				>
					特定商取引に基づく表記
				</Link>
			</Box>
		</Box>
	);
};

export default HomePageSideMenu;
