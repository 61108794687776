import {
  BlueprintFormatType,
  BlueprintLanguageType,
  BlueprintPatternType,
  BlueprintQualityType,
  BlueprintToneType,
} from "../utils/constrants";
import { Blueprint } from "../utils/interfaces";
import { apiClient } from "./apiClient";

export type BlueprintGetAllRequest = {
  keyword: string | null;
  is_star: boolean | null;
  sort_by: string | null;
  order: "asc" | "desc" | null;
};

export type BlueprintCreateRequest = {
  title: string;
  pattern: BlueprintPatternType;
  prompt: string;
  paste_text: string;
  format: BlueprintFormatType;
  character_length: number;
  language: BlueprintLanguageType;
  purpose: string;
  tone: BlueprintToneType;
  quality: BlueprintQualityType;
  file_ids: string[];
};

export type BlueprintUpdateRequest = {
  title: string;
  prompt: string;
  paste_text: string;
  format: BlueprintFormatType;
  character_length: number;
  language: BlueprintLanguageType;
  purpose: string;
  tone: BlueprintToneType;
  quality: BlueprintQualityType;
};

export const getAll = async (params: BlueprintGetAllRequest) => {
  const response = await apiClient.get(`/api/blueprint/search`, {
    params: params,
  });
  const data = response.data as Blueprint[];
  return data;
};

export const get = async (id: number) => {
  const response = await apiClient.get(`/api/blueprint/${id}`);
  const data = response.data as Blueprint;
  return data;
};

export const create = async (params: BlueprintCreateRequest) => {
  const response = await apiClient.post(`/api/blueprint`, params);
  const data = response.data as Blueprint;
  return data;
};

export const update = async (id: number, params: BlueprintUpdateRequest) => {
  const response = await apiClient.patch(`/api/blueprint/${id}`, params);
  const data = response.data as Blueprint;
  return data;
};

export const destory = async (id: number) => {
  const response = await apiClient.delete(`/api/blueprint/${id}`);
  const data = response.data as Blueprint;
  return data;
};